.new-call-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0px 24px 24px 24px;
}

/* Call Title Styles */
.call-title-card {
  margin-bottom: 24px;
  background: linear-gradient(to right, #fafafa, #ffffff);
  border-radius: 8px !important;
}

.call-title-card .ant-card-body {
  padding: 16px 24px;
}

.icon-wrapper {
  width: 36px;
  height: 36px;
  background: #1890ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.15);
  margin-right: 16px;
}

.title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.call-title {
  margin: 0 !important;
  color: #262626;
  line-height: 1.2;
}

.call-subtitle {
  color: #595959;
  font-size: 14px;
  margin-top: 2px;
}

.call-title-card:hover .icon-wrapper {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}

/* Optional hover effect */
.call-title-card:hover .icon-wrapper {
  transform: scale(1.05);
  transition: transform 0.2s ease;
}

/* Step Indicator Styles */
.step-indicator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  padding: 0 40px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.step-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #999;
  border: 2px solid #f0f0f0;
  transition: all 0.3s ease;
}

.step.active .step-circle {
  background: #fff;
  border-color: #1890ff;
  color: #1890ff;
}

.step.complete .step-circle {
  background: #1890ff;
  border-color: #1890ff;
  color: #fff;
}

.step-title {
  margin-top: 8px;
  font-size: 14px;
  color: #999;
  transition: all 0.3s ease;
}

.step.active .step-title {
  color: #1890ff;
  font-weight: 500;
}

.step.complete .step-title {
  color: #1890ff;
}

.step-line {
  flex: 1;
  height: 2px;
  background: #f0f0f0;
  margin: 0 8px;
  position: relative;
  top: -16px;
  transition: all 0.3s ease;
}

.step-line.complete {
  background: #1890ff;
}


/* Progress Step Styles */
.progress-card {
  border-radius: 12px;
  background: #ffffff;
}

.live-indicator {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;
}

.pulse-dot {
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-radius: 50%;
  position: relative;
  animation: pulse 2s infinite;
}

.status-text {
  color: #1890ff;
  font-weight: 500;
  font-size: 14px;
}

.progress-status-message {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

.progress-status-message h4 {
  margin-bottom: 8px;
  color: #262626;
}

.status-description {
  color: #595959;
  font-size: 14px;
  line-height: 1.6;
}

.info-card {
  background: #fafafa;
  border-radius: 8px;
  padding: 16px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.info-icon {
  font-size: 18px;
  color: #1890ff;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(24, 144, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(24, 144, 255, 0);
  }
}

/* Summary Step Styles */
.summary-card {
  border-radius: 12px;
}

.success-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.success-icon-wrapper {
  width: 48px;
  height: 48px;
  background: #52c41a;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
}

.objective-card,
.outcome-card,
.points-card,
.next-steps-card {
  background: #f8f9fa;
  border-radius: 8px;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px !important;
}

.points-list,
.next-steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.points-list li,
.next-steps-list li {
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.point-bullet {
  width: 6px;
  height: 6px;
  background: #1890ff;
  border-radius: 50%;
  margin-top: 8px;
}

.step-arrow {
  color: #52c41a;
  margin-top: 4px;
}

.new-call-button {
  padding: 0 32px;
  height: 44px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
  }
}

@keyframes wave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive Styles */
@media (max-width: 576px) {
  .new-call-container {
    padding: 16px;
  }
  
  .step-indicator {
    padding: 0 16px;
  }
  
  .call-progress {
    padding: 16px;
  }
}