.voxi-guide-modal .ant-modal-content {
  border-radius: 16px;
  padding: 0;
}

.voxi-guide-modal .ant-modal-body {
  padding: 0;
}

.guide-content {
  max-height: 80vh;
  overflow-y: auto;
  padding: 32px;
}

.guide-content::-webkit-scrollbar {
  width: 8px;
}

.guide-content::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 4px;
}

.guide-content::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

/* Header and Content Spacing */
.guide-header {
  text-align: center;
  margin-bottom: 24px;
}

.guide-header h2 {
  margin: 0;
  color: #2B2B2B;
}

.guide-content section {
  margin-bottom: 24px;
}

.guide-content section:last-child {
  margin-bottom: 0;
}

/* Alert Styles */
.guide-content .ant-alert {
  margin-bottom: 24px;
}

.guide-content .ant-alert ul {
  padding-left: 20px;
  margin-bottom: 0;
}

.guide-content .ant-alert li {
  margin-bottom: 8px;
}

.guide-content .ant-alert li:last-child {
  margin-bottom: 0;
}

/* Card Styles */
.feature-card {
  height: 100%;
  background: #fff;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.feature-card .ant-card-body {
  padding: 24px;
}

/* Card Header Alignment */
.feature-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.feature-card-header .ant-typography {
  margin: 0 !important;
  line-height: 24px !important;
}

.feature-icon {
  font-size: 24px;
  color: #2B2B2B;
  margin-right: 12px;
  display: flex;
  align-items: center;
}

/* List Styles */
.feature-list {
  margin: 16px 0 0;
}

.feature-list .ant-list-item {
  padding: 8px 0;
  border-bottom: none;
}

.list-item-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-align: left;
}

.list-icon {
  color: #2B2B2B;
  margin-right: 8px;
  margin-top: 4px;
  flex-shrink: 0;
}

.list-item-content span:last-child {
  flex: 1;
}

/* Typography Styles */
.feature-card .ant-typography {
  color: #4B5563;
  margin-bottom: 16px;
}

.feature-card .ant-typography:last-child {
  margin-bottom: 0;
}

/* Secondary Text / Example Box */
.ant-typography.ant-typography-secondary {
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 16px !important;
  font-size: 14px;
  color: #6B7280;
  display: block;
}

/* Row Spacing */
.guide-content .ant-row {
  margin-bottom: 0 !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .guide-content {
    padding: 24px;
  }
  
  .feature-card .ant-card-body {
    padding: 16px;
  }
  .ant-typography.ant-typography-secondary {
    margin-top: 0px;
  }
}