.app-container {
  min-height: 100vh;
  background: #f5f5f5;
}

.auth-loading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
}

.segmented-container {
  background: white;
  padding: 4px;
  border-radius: 9999px;
  display: flex;
  gap: 4px;
}

.loading-indicator {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1890ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fixed-header-group {
  position: sticky;
  top: 0;
  z-index: 100;
}

.announcement-strip {
  width: 100%;
  background: #1a1a1a;
  padding: 12px 24px;
  color: white;
  z-index: 1000;
  margin-bottom: 14px;
}

.desktop-announcement {
  display: block;
}

.mobile-announcement {
  display: none;
}

.announcement-content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1700px;
  margin: 0 auto;
  gap: 12px;
  text-align: center;
}

.announcement-icon {
  font-size: 16px;
  color: #ffffff;
}

.announcement-text {
  color: white;
  margin: 0;
  text-align: center;  /* Add this */
  flex: 0 1 auto;     /* Change from flex: 1 */
}

.announcement-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.announcement-highlight {
  font-weight: 600;
  color: #ffffff;
}

.announcement-body {
  color: rgba(255, 255, 255, 0.9);
}

.interested-button {
  color: white !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: transparent;
  border-radius: 4px;
  padding: 4px 12px;
}

.interested-button:hover {
  border-color: rgba(255, 255, 255, 0.5);
  color: white !important;
}

.close-button {
  color: rgba(255, 255, 255, 0.7) !important;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  color: white !important;
}

.header-container {
  background: #f5f5f5;
  padding: 0 24px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: none;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  max-width: 1700px;
  margin: 0 auto;
  position: relative;
  border-bottom: none;
}

.header-logo {
  height: 35px;
  width: 153px;
}

.logo-image {
  height: 100%;
  width: auto;
  padding-bottom: 5px;
}

.ant-avatar.ant-avatar-circle.ant-avatar-icon.ant-dropdown-trigger.user-avatar.css-dev-only-do-not-override-no4izc {
  margin-bottom: 8px;
}

.nav-button {
  border-radius: 9999px !important;
  display: flex;
  align-items: center;
  gap: 8px;
  height: auto;
  border: none !important;
  text-decoration: none !important;
  box-shadow: none;
}

.nav-button {
  border-radius: 9999px !important;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 16px !important;
  height: auto;
  background: white !important;
  border: none !important;
  text-decoration: none !important;  /* Add this */
}

.nav-button:hover {
  background: #f8f8f8 !important;
}

.nav-button.active {
  background: #2B2B2B !important;
  color: white !important;
}

.nav-button.active:hover {
  background: #3a3a3a !important;
}

.nav-button.active .anticon {
  color: white !important;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Credits Display Styles */
.credits-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.credits-display {
  position: relative;
  height: 24px;
  min-width: 48px;
}

.credits-badge {
  position: absolute;
  top: -2px;
  right: -24px;
  height: 24px;
  min-width: 48px;
  padding: 0 8px;
  background: #52c41a;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.credits-badge.error {
  background: #ff4d4f;
}

.credits-loading {
  background: #f0f0f0;
}

.loading-placeholder {
  width: 24px;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
  animation: pulse 1.2s ease-in-out infinite;
}

@keyframes pulse {
  0% { opacity: 0.3; transform: scaleX(0.8); }
  50% { opacity: 0.5; transform: scaleX(1.2); }
  100% { opacity: 0.3; transform: scaleX(0.8); }
}

.user-avatar {
  cursor: pointer;
  background: #1890ff;
}

.content-container {
  margin: 24px;
  padding: 0px 24px 24px 24px;
  background: #F5F5F5;
  border-radius: 4px;
  min-height: 280px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Add these new rules at the end of your existing CSS */
.hide-mobile {
  display: flex;
}

.show-mobile {
  display: none;
}

.mobile-menu-button {
  border: none !important;
  padding: 8px !important;
  height: auto !important;
}

.mobile-drawer .ant-drawer-body {
  padding: 0;
}

.mobile-credits {
  color: rgba(0, 0, 0, 0.45);
}


@media (max-width: 768px) {

  .announcement-strip {
    padding: 8px 16px;
  }

  .desktop-announcement {
    display: none;
  }
  
  .mobile-announcement {
    display: block;
  }
  
  .announcement-content {
    font-size: 12px;
    gap: 8px;
  }
  
  .header-container {
    padding: 0 16px;
  }

  .announcement-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    white-space: nowrap;
  }

  .hide-mobile {
    display: none;
  }
  .announcement-strip {
    padding: 8px 12px;
  }
  
  .announcement-content {
    gap: 8px;
  }
  
  .announcement-icon {
    font-size: 16px;
  }

  .content-container {
    padding: 0px;
  }

  .show-mobile {
    display: flex;
  }

  .header-content {
    width: 90%;
  }

  .mobile-menu-button {
    margin-left: auto;
  }

  .header-menu .ant-menu-item {
    padding: 0 12px;
  }

  .announcement-strip {
    padding: 8px 16px;
  }

  .announcement-content {
    font-size: 12px;
  }

  .credits-badge {
    min-width: 40px;
    height: 20px;
    font-size: 12px;
    right: -20px;
  }

  .credits-button {
    padding: 4px 12px;
  }
}