.b2b-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    background: #fff;
  }
  
  .logo-container {
    margin-bottom: 48px;
  }
  /* Add these styles to your existing B2BIntro.css */
.close-button1 {
  position: fixed;
  top: 24px;
  right: 24px;
  z-index: 1000;
  font-size: 20px;
  width: 40px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
}

.close-button1:hover {
  background: rgba(0, 0, 0, 0.2);
}
  .logo {
    height: 32px;
    object-fit: contain;
  }
  
  .content {
    max-width: 800px;
    width: 100%;
    text-align: center;
  }
  
  .subtitle {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
  }
  
  .features {
    display: flex;
    justify-content: center;
    gap: 4px;
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
  
  .feature {
    font-size: 18px;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.85);
  }
  
  .separator {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
  }
  
  .main-title {
    font-size: 48px !important;
    line-height: 1.2 !important;
    margin-bottom: 32px !important;
    font-weight: 600 !important;
  }
  
  .description {
    font-size: 18px;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 48px;
  }
  
  .signup-section {
    max-width: 400px;
    margin: 0 auto;
  }
  
  .signup-text {
    display: block;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.65);
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .email-input {
    height: 48px;
    border-radius: 8px;
  }
  
  .signup-button {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background: #52c41a;
    border-color: #52c41a;
  }

  .success-modal .ant-modal-content {
    border-radius: 12px;
    padding: 24px;
  }

  .success-modal .ant-modal-body {
    padding: 0;
  }

  .signup-button:hover {
    background: #73d13d !important;
    border-color: #73d13d !important;
  }
  
  @media (max-width: 768px) {
    .main-title {
      font-size: 36px !important;
    }
  
    .description {
      font-size: 16px;
    }
  
    .feature, .separator, .subtitle {
      font-size: 16px;
    }
  }