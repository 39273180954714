.cost-estimate {
  background: #fafafa;
  border-radius: 8px;
  padding: 16px;
  margin: -8px 0 16px 0;
  border: 1px solid #f0f0f0;
  animation: fadeIn 0.3s ease-in-out;
}

.cost-estimate-header {
  margin-bottom: 12px;
}

.total-cost {
  margin: 0 !important;
  color: #262626;
}

.per-minute {
  font-size: 14px;
  margin-left: 4px;
}

.cost-breakdown {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.cost-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.cost-details {
  background: #fff;
  border-radius: 6px;
  padding: 12px;
}

.cost-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 4px 0;
}

.cost-row:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.credit-info-card {
  margin-bottom: 24px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
}

.credit-info-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 44px;
}

.credit-info-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.credit-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 8px 0;
  display: block;
  color: #1f2937;
}

.credit-info-text {
  color: #4b5563;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  display: block;
}

.get-credits-button {
  background: #2B2B2B;
  border: none;
  border-radius: 9999px;
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 24px;
}

.get-credits-button:hover {
  background: #3a3a3a !important;
}

.credit-info-text {
  color: #4b5563;
  font-size: 14px;
}

.credits-title {
  margin: 0 0 8px 0 !important;
  color: #1677ff;
}

.credits-description {
  display: block;
  margin-bottom: 12px;
  color: #262626;
}

.credits-packages1 {
  margin-top: 12px;
}

.package-tags {
  display: flex;
  gap: 8px;
  margin-top: 8px;
  flex-wrap: wrap;
}

.package-tag {
  background: white;
  border: 1px solid #1677ff;
  color: #1677ff;
  padding: 2px 12px;
  border-radius: 12px;
  font-size: 13px;
}

.learn-more-alert {
  margin-bottom: 16px;
}

.learn-more-link {
  text-decoration: underline;
}

.setup-card {
  margin-bottom: 16px;
}

.credits-status {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #f0f0f0;
  animation: fadeIn 0.3s ease-in-out;
}

.balance-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 8px;
}

.duration-estimate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 12px;
}

.low-credits-alert {
  animation: fadeIn 0.3s ease-in-out;
}

.cost-estimate-loading {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  margin: -8px 0 16px 0;
}

.cost-estimate-error {
  margin: -8px 0 16px 0;
  animation: fadeIn 0.3s ease-in-out;
}

.submit-error-alert {
  margin-bottom: 16px;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .credit-info-content {
    display: flex;
    min-height: 44px;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .get-credits-button {
    background: #2B2B2B;
    border: none;
    border-radius: 9999px;
    height: 40px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 0px;
  }
  .credit-info-text-section {
    padding-bottom: 16px;
  }
}