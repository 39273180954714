.credits-view {
  min-height: 100vh;
  padding: 2rem;
}

.credits-content {
  max-width: 600px;
  margin: 0 auto;
}

.credits-header {
  text-align: center;
  margin-bottom: 4rem;
}

.credits-header h2 {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #666;
  font-size: 1rem;
}

.purchase-container {
  background: #ffffff;
  padding: 3rem;
  border-radius: 24px;
}

.amount-display {
  text-align: center;
  margin-bottom: 3rem;
}

.input-group {
  display: inline-flex;
  align-items: center;
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  border: 1px solid #eaeaea;
}

.currency {
  font-size: 1.5rem;
  font-weight: 500;
  margin-right: 0.5rem;
  color: #000;
}

.amount-input {
  font-size: 1.5rem !important;
  font-weight: 500;
  width: 120px !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.amount-input:focus {
  box-shadow: none !important;
}

.slider-container {
  margin-bottom: 3rem;
  padding: 0 1rem;
}

.credits-slider {
  margin-bottom: 0.75rem;
}

.credits-slider .ant-slider-rail {
  background-color: #eaeaea;
  height: 4px;
}

.credits-slider .ant-slider-track {
  background-color: #3184ff;
  height: 4px;
}



.credits-slider .ant-slider-handle:focus {
  box-shadow: none;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 0.875rem;
}

.credits-info {
  text-align: center;
  margin-bottom: 2rem;
  color: #666;
}

.credits-amount {
  display: block;
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
  margin-top: 0.5rem;
}

.buy-button {
  display: block;
  width: 100%;
  height: 42px;
  border: none;
  font-size: 1rem;
}
/* Add these new classes to your existing CSS file */
.balance-card {
  margin-bottom: 2rem;
  text-align: center;
  background: #ffffff;
  border-radius: 24px;
  padding: 2rem;
}

.balance-amount {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  color: #3184ff;
  margin-top: 0.5rem;
}

/* For the loading spinner container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.purchase-card {
  margin-top: 24px;
  padding: 24px;
}

.paypal-container {
  margin-top: 24px;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 640px) {
  .credits-view {
    padding: 1rem;
  }

  .purchase-container {
    padding: 2rem 1.5rem;
  }
}